import axios from "axios";
let commonMixin = {
  data() {
    return {
      genereatedURL: null,
      uploadLoading: false,
    };
  },
  methods: {
    uploadData(data) {
      this.uploadLoading = true;
      axios
        .post("https://recordly.pf.com.pk/api/uploadFile", data)
        .then((res) => {
          console.log("response", res);
          this.genereatedURL = res.data.data.path;
        })
        .catch((err) => {
          console.log("error", err);
        })
        .finally(() => {
          this.uploadLoading = false;
        });
    },
  },
};
export default commonMixin;
