<template>
  <v-app>
    <v-app-bar app color="white" elevation="0">
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/app-logo.png"
          transition="scale-transition"
          width="134"
        />
      </div>
    </v-app-bar>

    <v-main class="d-flex align-center">
      <router-view></router-view>
    </v-main>
    <v-footer padless>
      <v-row no-gutters>
        <v-card width="100%" flat tile class="grey--text px-0 footer-tab">
          <v-card-text class="d-flex align-center justify-center px-0">
            <v-btn
              class="mx-1 grey--text text-capitalize btn-dir"
              color="red"
              text
              to="/"
              large
            >
              <v-img width="24px" src="@/assets/mic.png"> </v-img>
              Audio
            </v-btn>
            <v-btn
              class="mx-1 grey--text text-capitalize btn-dir"
              color="white"
              text
              to="/screen"
              large
            >
              <v-img width="24px" src="@/assets/screen.png"> </v-img>

              Screen
            </v-btn>
            <v-btn
              class="mx-1 grey--text text-capitalize btn-dir"
              color="white"
              text
              to="/help"
              large
            >
              <v-img width="20px" src="@/assets/help-logo.png"> </v-img>

              Help
            </v-btn>
          </v-card-text>
        </v-card>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  watch: {
    /**
     * This watcher is responsible to watch for any route changes and then set title of the page accordingly
     *
     * @param {object} to The payload object contains meta
     * @return none
     */
    $route: {
      handler: (to) => {
        document.title = to.meta.title || "Recordly";
      },
      immediate: true,
    },
  },
  mounted() {
    console.log("tEST");
  },
};
</script>
<style>
.footer-tab {
  border-top: 1px solid #ebe9e9 !important;
}
.btn-dir .v-btn__content {
  flex-direction: column !important;
}
</style>
