import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "audio",
    meta: {
      title: "Recordly - Audio Recorder",
    },
    component: () =>
      import(
        /* webpackPrefetch: true */
        /* webpackChunkName: "about" */ "@/components/audio/AudioRecorder.vue"
      ),
  },
  {
    path: "/screen",
    name: "screen",
    meta: {
      title: "Recordly - Screen Recorder",
    },
    component: () =>
      import(
        /* webpackPrefetch: true */
        /* webpackChunkName: "about" */ "@/components/screen/ScreenRecorder.vue"
      ),
  },
  {
    path: "/help",
    name: "screen",
    meta: {
      title: "Recordly - Help",
    },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "about" */ "@/components/Help.vue"
      ),
  },
  {
    path: "/permission",
    name: "permission",
    meta: {
      title: "Recordly - Permission",
    },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "about" */ "@/components/Permission.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
